<div class="p-grid cm-app">
  <div class="cm-header p-col-12 p-grid p-ai-center p-nogutter">
    <h1>Card Matching Game</h1>
  </div>
  <div class="cm-main-wrapper p-col-12">
    <main class="cm-main-panel p-grid p-jc-center p-ai-center p-nogutter">
      <div class="cm-page-container p-shadow-2">
        <router-outlet></router-outlet>
      </div>
    </main>
  </div>
</div>
